<template>
    <!--TODO: implement exec astp_Arena_DocumentSyncProcessParticipants (show if some prop or calculation = true if a process is ongoing)-->
    <div class="row">
        <div class="col-12 mt-1">
            <template v-if="props.dataObject.current.HasConfidentialityGroup">
                <div class="alert alert-warning" role="alert" style="font-size:14px">
                    <strong>{{ $t('Restricted Access: ') }}</strong> {{ $t('This document is only available to the individuals listed in "Grant Access to Person" and members of the confidentiality group: ') }}<strong class="ms-2">{{ props.dataObject.current.ConfidentialityGroup }}</strong>.
                </div>
            </template>
        </div>
        <div class="col-md-4 col-12 mt-1">
            <div class="d-flex justify-content-between align-items-center">
            <label for="reviewResp">{{$t('Review Responsible')}}</label>
                <div class="btn-group">
                    <ODataLookup :data-object="dsLocal_PersonsWithAccess" :bind="sel => { props.dataObject.current.ReviewResponsible = sel.Name; props.dataObject.current.ReviewResponsible_ID = sel.ID; saveDataObject(); }">
                        <template #target="{ target }">
                            <a href="#" class="btn btn-link btn-sm py-0" :ref="target"> {{ $t('Set Person') }}</a>
                        </template>
                        <OColumn name="ID" width="80"/> 
                        <OColumn name="Name" width="300"/>
                    </ODataLookup>

                    <ORolesLookup
                        :bind="sel => { props.dataObject.current.ReviewResponsibleRole = sel.Title; props.dataObject.current.ReviewResponsibleRole_ID = sel.ID; props.dataObject.current.ReviewResponsible = null; saveDataObject(); }"
                        whereClause="Closed IS NULL"
                        >
                        <template #target="{ target }">
                            <a href="#" class="btn btn-link btn-sm py-0" :ref="target"> {{ $t('Set Role') }}</a>
                        </template>
                    </ORolesLookup>
                </div>
            </div>
            <input id="reviewResp" class="form-control form-control-sm" 
                :value="props.dataObject.current.ReviewResponsible ? props.dataObject.current.ReviewResponsible : (props.dataObject.current.ReviewResponsibleRole ? `${$t('Role')}: ${props.dataObject.current.ReviewResponsibleRole}` : '')" readonly>
        </div>

        <div class="col-md-4 col-12 mt-1" v-if="ReviewConsolidationEnabled">
            <div class="d-flex justify-content-between align-items-center">
                <label for="reviewCons">{{$t('Review Consolidator')}}</label>
                <div class="btn-group">
                    <ODataLookup :data-object="dsLocal_PersonsWithAccess" :bind="sel => { props.dataObject.current.ReviewConsolidator = sel.Name; props.dataObject.current.ReviewConsolidator_ID = sel.ID; saveDataObject(); }">
                        <template #target="{ target }">
                            <a href="#" class="btn btn-link btn-sm py-0" :ref="target"> {{ $t('Set Person') }}</a>
                        </template>
                        <OColumn name="ID" width="80"/> 
                        <OColumn name="Name" width="300"/>
                    </ODataLookup>

                    <ORolesLookup
                        :bind="sel => { props.dataObject.current.ReviewConsolidatorRole = sel.Title; props.dataObject.current.ReviewConsolidatorRole_ID = sel.ID; props.dataObject.current.ReviewConsolidator = null; saveDataObject(); }"
                        whereClause="Closed IS NULL"
                        >
                        <template #target="{ target }">
                            <a href="#" class="btn btn-link btn-sm py-0" :ref="target"> {{ $t('Set Role') }}</a>
                        </template>
                    </ORolesLookup>
                </div>
            </div>
            <input id="reviewCons" class="form-control form-control-sm" 
                :value="props.dataObject.current.ReviewConsolidator ? props.dataObject.current.ReviewConsolidator : (props.dataObject.current.ReviewConsolidatorRole ? `${$t('Role')}: ${props.dataObject.current.ReviewConsolidatorRole}` : '')" readonly>
        </div>

        <div class="col-md-4 col-12" v-if="IDCEnabled">
            <OFormControl :title="$t('IDC Responsible')">
                <ODataLookup :data-object="dsLocal_PersonsWithAccess"
                    openonfocus
                    :bind="sel=> { props.dataObject.current.IDCResponsible_ID=sel.ID; props.dataObject.current.IDCResponsible=sel.Name; saveDataObject()}"
                    class="form-control form-control-sm"
                    :height="400"
                    :value="props.dataObject.current.IDCResponsible">
                    <OColumn name="ID" width="80"/> 
                    <OColumn name="Name" width="300"/>
                </ODataLookup>
            </OFormControl>
        </div>
    </div>

    <DistSetupPersons :dataObject="props.dataObject" ref="DistSetupPersonsRef" class="mt-3"></DistSetupPersons>
    <DistSetupOrgUnits :dataObject="props.dataObject" ref="DistSetupOrgUnitsRef" class="mt-3"></DistSetupOrgUnits>
    <DistSetupGroups :dataObject="props.dataObject" ref="DistSetupGroupsRef" @insertDelete="refreshGrids" class="mt-3 mb-1"></DistSetupGroups>
    <ShareWithOrgUnitsRoles :dataObject="props.dataObject" class="mt-3"/> 
    <PublishedToOrgUnits :dataObject="props.dataObject" class="mt-3"/> 
</template>

<script setup>
    import { ref } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'
    import DistSetupPersons from 'arena.vue.components.DistributionSetupPersons.vue'
    import DistSetupOrgUnits from 'arena.vue.components.DistributionSetupOrgUnits.vue'
    import DistSetupGroups from 'arena.vue.components.DistributionSetupGroups.vue'
    import PublishedToOrgUnits from 'arena.vue.components.DistributionPublishToOrgUnits.vue'
    import ShareWithOrgUnitsRoles from 'arena.vue.components.ShareWithOrgUnitsRoles.vue'
    import { ORolesLookup } from 'o365-system-lookups';
    import { useDataObjectEventListener } from "o365-vue-utils";

    const props = defineProps({
        dataObject: Object
    })

    const DistSetupPersonsRef = ref(null),  DistSetupOrgUnitsRef = ref(null),  DistSetupGroupsRef = ref(null);
    const IDCEnabled = ref(false), ReviewConsolidationEnabled = ref(false);

    const personsWithAccessDatasourceDefinition = {
        id: 'dsLocal_PersonsWithAccess',
        viewName: 'aviw_Arena_PersonsWithAccess',
        appId: props.dataObject.appId,
        maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"},
                {name: "Document_ID", type: "string"}
            ]
    }

    const dsSettingsDef = {
        id: 'dsLocal_Settings',
        viewName: 'atbv_Arena_Settings',
        appId: props.dataObject.appId,
        maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        fields:
            [
                {name: "IDCProcessEnabled", type: "bool"},
                {name: "ReviewConsolidationEnabled", type: "bool"}
            ]
    }

    const dsLocal_PersonsWithAccess = getOrCreateDataObject(personsWithAccessDatasourceDefinition);
    const dsLocal_Settings = getOrCreateDataObject(dsSettingsDef);

    dsLocal_Settings.load().then(() => {
        if(dsLocal_Settings.data.length > 0){
            IDCEnabled.value = dsLocal_Settings.data[0].IDCProcessEnabled;
            ReviewConsolidationEnabled.value = dsLocal_Settings.data[0].ReviewConsolidationEnabled;
        }
    })

    useDataObjectEventListener(props.dataObject, "AfterSave", (pData) => {
        if ("DeliveryVerified" in pData.values || "RevisionDate" in pData.values || "ReleaseCode_ID" in pData.values) {
            DistSetupGroupsRef.value.refreshGrid();
            refreshGrids();
        }
    });

    function refreshGrids(){
        DistSetupPersonsRef.value.refreshGrid();
        DistSetupOrgUnitsRef.value.refreshGrid();
    }

    function saveDataObject(){
        props.dataObject.save();
    }
</script>